/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2022-05-07 00:05:11
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-03-13 19:25:34
 */

import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";
import http from "../http/axios_init";
import Cookies from "js-cookie";
import Layout from "@/layout";
import RouteLayout from "@/layout/layout2.vue";
import config from "../config/config";

//加载中
var isLoading = false;

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/login/login"),
  },
  {
    path: "/dashboard",
    component: Layout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/dashboard/dashboard"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/profile"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

/**
 * 前置守卫
 */
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (to.meta.title) {
    //判断是否有标题
    document.title = "友邦-" + to.meta.title;
  } else {
    document.title = "友邦-征集管理";
  }
  if (to.name == "login") {
    next();
  } else {
    var token = localStorage.getItem("token");
    if (!token) {
      next("/");
    } else {
      if (!isLoading) {
        isLoading = true;
        localStorage.removeItem(config.CURRENT_META_KEY);
        //加载路由数据
        var res = await http.get("admin/v1/center/vue_route");
        if (res.code == 0) {
          res.data.forEach((item) => {
            var route = {
              path: `/${item.path}`,
              name: item.name,
              meta: item.meta,
              component:
                item.component == "layout" || item.component == ""
                  ? Layout
                  : item.component == "layout2"
                  ? RouteLayout
                  : () => import(`@/${item.component}`),
              children: [],
            };
            if (item.children.length > 0) {
              adornRoutes(item, route, to);
            } else {
              if (to.path.substring(1) == item.path) {
                localStorage.setItem(
                  config.CURRENT_META_KEY,
                  JSON.stringify(item.meta)
                );
              }
            }
            router.addRoute(route);
          });
          next({ ...to, replace: true });
        }
      } else {
        next();
      }
    }
  }
});

/**
 * 后置守卫
 */
router.afterEach(() => {
  NProgress.done();
});

/**
 * 处理路由数据
 * @param {*} item
 * @param {*} route
 */
function adornRoutes(item, route, to) {
  item.children.forEach((child) => {
    var route_item = {
      path: `/${child.path}`,
      name: child.name,
      meta: child.meta,
      component:
        child.component == "layout" || child.component == ""
          ? Layout
          : child.component == "layout2"
          ? RouteLayout
          : () => import(`@/${child.component}`),
      children: [],
    };
    if (to.path.substring(1) == child.path) {
      localStorage.setItem(config.CURRENT_META_KEY, JSON.stringify(child.meta));
    }
    route.children.push(route_item);
    adornRoutes(child, route_item);
  });
}

export default router;
